import React, { useState } from "react";
import { Link } from "gatsby";
import { Nav, Container, Col, Row } from "react-bootstrap";
import classNames from 'classnames';
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";
import Searchpopup from '../search-overlay/search-overlay';
import MenuBG from "../../../src/images/menu-bg.png";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
/**
 * Assets
 */

import './styles/_index.scss';

/**
 * Components
 */

import { Logo, Search } from '@components/icon/icon';
import ModalNav from '@components/modal-nav/modal-nav';
import SearchOverlayLink from '@components/modal-nav/search-form-link';

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Header = (props) => {

    const data = useStaticQuery(graphql`
    query BurgerQuery {  
      menubg: file(relativePath: { eq: "menu-bg.png" }) {
        childImageSharp {
            fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
     }
      site {
        siteMetadata {
          title
        }
      }
      
    allStrapiAllMenus(filter: {Sort: {}}, sort: {order: ASC, fields: Sort}) {
      edges {
        node {
          id
          Label
          Show_In_Burger_Menu
          URL
          Secondary_URL
          Link_Type
          Hide_Menu
          main_parent {
            URL
            Show_In_Burger_Menu
            Label
            Hide_Menu
          }
          sub_parent {
            URL
            Label
            Hide_Menu
          }
        }
      }
    }    
    }
  `)

  function searchOverlay(e) {
    $("body")
        .addClass("modal-open")
        .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }

  if (typeof window !== "undefined") {
  $(window).scroll(function () {
      var sticky = $('.sticky-header'),
          scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
  });
  }
  const [activeModal, setActiveModal] = useState(false);
  const MenuClose = () => {
    setTimeout(function(){ 
    if($('.menu-side-img.overlay-burger').is(":visible")) {      
      $('button.toggle-nav.is-active').removeClass('darkclose');
    } else {
      $('button.toggle-nav.is-active').addClass('darkclose');
    } 
  }, 1000);
  }
  return (
    <>
    <Searchpopup/>    
    <header className={props.headertype == 'nosticky'?'header':'header sticky-header'}>
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Link to="/" className="navbar-brand"><Logo /></Link>
          </Col>
          <Col xs="auto" className="ml-auto right-nav">
            <Nav className="mr-auto">
              <ul className="main-nav">
         
                {props.Topmenus.edges.map(({node}, key) => {
                  return <>
                  {node.Menu_Name == 'Find a Property' && 
                  <li className="d-none d-lg-block">
                    <SearchOverlayLink label={node.Menu_Name} />                    
                  </li>
                  }
                  {node.Menu_Name == 'Why Choose Us' &&
                  <li className="d-none d-lg-block">
                    <Link to="/property-services/sell/why-choose-us-to-sell-property/">{node.Menu_Name}</Link>
                  </li>
                  }
                  {node.Menu_Name != 'Find a Property' && node.Menu_Name != 'Why Choose Us' &&
                  <li className="d-none d-lg-block">
                    {node.Select_Menu.Secondary_URL ?
                    <Link to={`${node.Select_Menu.Secondary_URL}/`}>{node.Menu_Name}</Link>
                    :<Link to={`/${node.Select_Menu.URL}/`}>{node.Menu_Name}</Link>
                  }
                    </li>
                  }

                  </>
                })}
                  <li className="d-none d-lg-block sb-myacc" >
                    <Link to={myaccountURL} className="btn-circle">MyAccount</Link>
                  </li>

                <li className="d-inline searchmagni">
                  <a className="triggersearchoverlay" href="javascript:;" onClick={searchOverlay}>
                    <Search />
                  </a>
                </li>
                

              </ul>
            </Nav>
          </Col>
          <Col xs="auto" className="toggle-nav-holder">
            <button 
              className={classNames('toggle-nav', {'is-active': activeModal})} 
              onClick={() => {
                setActiveModal(!activeModal); MenuClose();
                document.querySelectorAll('body,html').forEach(item => item.classList.toggle('overflow'))
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </Col>
        </Row>
      </Container>
      <ModalNav BurgerMenu={data.allStrapiAllMenus} active={activeModal} toggle={setActiveModal} />
      {activeModal &&
      <div className="d-none d-lg-block menu-side-img overlay-burger" data-aos="slide-down" data-aos-duration="1000">
        <Img fluid={data.menubg.childImageSharp.fluid} alt="Menu - Anthony Pepe" />
        <div className="overlay-landing"></div>
      </div>
      } 
    </header>
  
    </>
  )
}

export default Header;
