import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Col, Row } from "react-bootstrap";
import ContactBox from '@components/contact-box/contact-box';
import Img from 'gatsby-image';
import ReactMarkdown from "react-markdown/with-html"

export default function Header() {
  return (
    <StaticQuery
      query={graphql`
        query BurgerMemberQuery {
        strapiGlobalConfig {
          Burger_Team_Member_Above_Title
          Burger_Team_Member_Name
          Burger_Team_Member_Phone
          Burger_Team_Member_Calendly
          Burger_Team_Member_Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
        }
      `}
      render={data => (
          <div className="right-side">
          <ReactMarkdown source={data.strapiGlobalConfig.Burger_Team_Member_Above_Title} escapeHtml={false}/>
            <div className="contact-box">
              <Row>
                <Col xs="auto">
                <Img fluid={data.strapiGlobalConfig.Burger_Team_Member_Image.childImageSharp.fluid} alt="avatar" />
                </Col>
                <div className="col"><span className="small-contact-heading">{data.strapiGlobalConfig.Burger_Team_Member_Name}</span><div class="links"><a href={`tel:${data.strapiGlobalConfig.Burger_Team_Member_Phone}`}>{data.strapiGlobalConfig.Burger_Team_Member_Phone}</a></div></div>
              </Row>
            </div>
          </div>

      )}
    />
  )
}
