import React, {useEffect, useState} from "react";
import Helmet from "react-helmet"

const StaticReviews = () => {
    const [renderComponent, setRenderComponent] = useState(false);

    useEffect(() => {

        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => { 
            if (renderComponent === false) {
              setRenderComponent(true)
            }
          })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        }) 
    }, [])


    return (
        <>
        {renderComponent &&
        <>
        <Helmet 
  script={[{ 
    type: "text/javascript", 
    innerHTML: '(function() {var se = document.createElement("script"); se.type = "text/javascript"; se.async = true; se.src ="//storage.googleapis.com/moneypennychat/js/	2b334a16-b428-4d60-b5b0-92b1db5c1f0b.js"; var done = false; se.onload = se.onreadystatechange = function() {if (!done&&(!this.readyState||this.readyState==="loaded"||this.readyState==="complete")) {done = true;var seAgent;Moneypenny.setCallback("StartChat", function(email, msg, type) { if (type == "proactive") { ga("send","event","Moneypenny", "proactiveEngaged", "Proactive Start Chat");}});Moneypenny.setCallback("StartChat", function(msg) { ga("send","event","Moneypenny", "proactiveEngaged", "Manual Start Chat");});}};var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(se, s);})();'
  }]} />
   <Helmet 
  script={[{ 
    type: "text/javascript", 
    innerHTML: '(function() { window.skillStore = { clientId: "pcm_64Yg7" }; var s1 = document.createElement("script"), s2 = document.createElement("script"), s0 = document.getElementsByTagName("body"); s1.async = true; s2.async = true; s1.src = "https://videos.theskill.store/index.js"; s2.src = "https://videos.theskill.store/ddt.min.js"; s1.charset = "UTF-8"; s2.charset = "UTF-8"; s1.setAttribute("crossorigin", "*"); s2.setAttribute("crossorigin", "*"); s0[0].appendChild(s1); s0[0].appendChild(s2); var l1 = document.createElement("link"), l2 = document.createElement("link"), l0 = document.getElementsByTagName("head"); l1.rel = "stylesheet"; l1.type = "text/css"; l1.href = "https://videos.theskill.store/index.css"; l2.rel = "stylesheet"; l2.type = "text/css"; l2.href = "https://fonts.googleapis.com/css?family=Roboto"; l0[0].appendChild(l2); l0[0].appendChild(l1); })();'
  }]} />
  </>
}
  </>
    )
}
export default StaticReviews
